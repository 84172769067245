<template>
  <base-layout-two
    :page-title="`Payment Method`"
    :page-default-back-link="`/users/${$route.params.id}`"
    end-button-text="Edit"
    :end-button-url="`/users/${$route.params.id}/payment_methods/${$route.params.payment_method_id}/edit`"
  >
    <section class="ion-padding-start ion-padding-top">
      <h1>Payment Method</h1>
    </section>

    <section class="ion-padding">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <base-field-display label-text="Name">
              <p class="ion-text-capitalize">
                Test Name
              </p>
            </base-field-display>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <base-field-display label-text="Last 4">
              <p class="ion-text-capitalize">
                5555
              </p>
            </base-field-display>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <base-field-display label-text="Expiration Date">
              <p class="ion-text-capitalize">
                10/22
              </p>
            </base-field-display>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <base-field-display label-text="Zipcode">
              <p class="ion-text-capitalize">
                92618
              </p>
            </base-field-display>
          </ion-col>
        </ion-row>

        
      </ion-grid>
    </section>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol } from "@ionic/vue";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  data() {
    return {
      user: {},
      care_plan: {
        mother: {},
        baby: {}
      },
    };
  },
  ionViewWillEnter() {
    
  },
  methods: {
    async fetchCarePlan() {
      await this.axios
        .get(
          `${process.env.VUE_APP_API}/care_plans/${this.$route.params.care_plan_id}`
        )
        .then((response) => {
          this.care_plan = response.data.care_plan;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>